import moment from 'moment';
import { IBidding, IItem } from '../models';
import {
  FILTER_OBJECT_AUCTION_CATEGORY,
  FILTER_OBJECT_AUCTION_TYPE,
} from '../models/general.types';
import { AuctionLotsFilterOptions } from '../services/api/response.types';
import _ from 'lodash';

export interface SourceArrayItem {
  id: number;
}

export interface SourceMap<T extends SourceArrayItem> {
  saleEvent?: any;
  [id: number]: T | undefined;
}

export function arrayItemsToMap<T extends SourceArrayItem>(array: T[]) {
  return array.reduce<SourceMap<T>>((result, item) => ({ ...result, [item.id]: item }), {});
}

export function mapItemsToArray<T extends SourceArrayItem>(source: SourceMap<T>) {
  return Object.entries(source).map(([_index, item]) => item) as T[];
}

export function updateMapItemById<T extends SourceArrayItem>(
  source: SourceMap<T>,
  update: Partial<T>,
) {
  if (update?.id) {
    const item = source[update.id];
    return { ...source, [update.id]: { ...item, ...update } };
  }

  return source;
}

export function updateArrayItemById<T extends SourceArrayItem>(
  source: T[],
  update: Partial<T>,
): T[] {
  return source.map((item) => (item.id === update.id ? { ...item, ...update } : item));
}

export function removeArrayItemById<T extends SourceArrayItem>(source: T[], id: number): T[] {
  return source.filter((item) => item.id !== id);
}

export function stringToAmount(value: string) {
  let amount = value.replace(/^\./, '');
  amount = amount.replace(/[^0-9.]/g, '');

  const parts = amount.split('.');
  if (parts.length > 1) {
    amount = parts[0] + '.' + parts[1].slice(0, 2);
  }
  return amount;
}

export function numberToLocaleString(value: number) {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function numberToAmount(value?: number) {
  if (!value) {
    return '0';
  }
  const numberOfDecimals = Number.isInteger(value) ? 0 : 2;
  return `${Number(value)
    .toFixed(numberOfDecimals)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

export function numberToAmountLabel(value?: number) {
  if (!value) {
    return 'RM 0';
  }
  const numberOfDecimals = Number.isInteger(value) ? 0 : 2;
  return `RM ${Number(value)
    .toFixed(numberOfDecimals)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

export function serverOptionsToPickerValues(data: AuctionLotsFilterOptions) {
  Object.keys(data).map((key) => {
    // @ts-ignore
    data[key].map((item: any) => {
      item.label = item.key;
      item.value = item.key;
      return item;
    });
  });
}

export function testProps(id: string) {
  return { testID: id, accessibilityLabel: id };
}

export function maskLatestFourSymbols(value: string) {
  const handledValue = value.slice(0, -4) + '****';
  return handledValue;
}

export function cutLongString(name: string) {
  return name.length > 24 ? `${name.slice(0, 24)}...` : name;
}

export const getLotInfo = (
  odometerBid?: number,
  odoUnitsBid?: string,
  conditionTypeBid?: string,
) => {
  const odometer = odometerBid ? numberToLocaleString(odometerBid) : 'N/A';
  const odoUnit = odoUnitsBid ? odoUnitsBid.toLowerCase() : '';
  const conditionType = conditionTypeBid;
  return `${odometer} ${odoUnit}${conditionType ? ` • ${conditionType}` : ''}`;
};

export function getBiddingStatus(item: IItem.Item): IBidding.STATUS {
  const noWinner = !item.bidding?.winnerId;
  const isUserWinner = item.bidding?.isWinning;
  const isRunning = item.bidding?.isRunning;
  const isOutbid = item.bidding?.isOutbid;
  const isOffer = item.bidding?.isOffer && isUserWinner;

  switch (true) {
    case isRunning && isUserWinner:
      return IBidding.STATUS.WINNING;
    case isRunning && isOutbid:
      return IBidding.STATUS.OUTBID;
    case !isRunning && isOffer:
      return IBidding.STATUS.OFFER;
    case !isRunning && noWinner:
      return IBidding.STATUS.LOTTED;
    case !isRunning && isUserWinner:
      return IBidding.STATUS.WON;
    case !isRunning && !isUserWinner:
      return IBidding.STATUS.LOST;
    case isOutbid:
      return IBidding.STATUS.OUTBID;
    default:
      return IBidding.STATUS.UNKNOWN;
  }
}

export const eventTypesArrayToLabels = (eventTypes?: string[]): string | undefined => {
  const labels = eventTypes?.map((key) => {
    const eventType = Object.values(FILTER_OBJECT_AUCTION_TYPE).find((val) => val.key === key);
    return (eventType && eventType.label) || key;
  });
  return labels?.join(', ');
};

export const eventCategoriesToLabels = (eventCategories?: string[]): string | undefined => {
  const labels = eventCategories?.map((key) => {
    const eventCategory = Object.values(FILTER_OBJECT_AUCTION_CATEGORY).find(
      (val) => val.key === key,
    );
    return (eventCategory && eventCategory.label) || key;
  });
  return labels?.join(', ');
};

export const isNumeric = (value: any) =>
  (typeof value === 'number' || (typeof value === 'string' && value.trim() !== '')) &&
  !isNaN(value as number);

export const dateUtils = {
  format: (timestamp: moment.MomentInput, format?: string, errorMessage = '-') => {
    return timestamp && format && moment(timestamp).isValid()
      ? moment(timestamp).format(format)
      : errorMessage;
  },
};

export function imageUrlWithResizer(url: string, width: number = 256): string {
  const resizer = 'https://d37znxaa4eaqnx.cloudfront.net/production/bypass/';
  return `${resizer}?w=${width}&u=${url}`;
}

export function calculateLiveNextBidAmount(currentBidAmount: number, increment: number) {
  return Math.round((currentBidAmount + increment) / increment - 0.5) * increment;
}

// CreditFormula from vcast (eg., "out=1000; return out;")
export function parseCreditFormula(creditFormula: string) {
  return _.toNumber(
    creditFormula.substring(creditFormula.indexOf('=') + 1, creditFormula.indexOf(';')),
  );
}

export const checkAuctionStarted = (item: IItem.Item): boolean => {
  const now = new Date().getTime();

  return now > moment(item?.saleEvent?.startAt)?.valueOf();
};

export const checkAuctionEnded = (item: IItem.Item, includeSniperTime?: boolean): boolean => {
  const now = new Date().getTime();

  if (includeSniperTime && item.bidding) {
    return now >= item.bidding.endTime;
  } else {
    return now >= moment(item?.saleEvent?.endAt)?.valueOf();
  }
};
