import { useSelector as reduxUseSelector } from 'react-redux';
import { IAuction, IItem, ILive } from '../../models';
import { STATUS } from '../../models/item/item.types';
import { MyPurchaseType } from '../../utils/constants';
import { MyPurchaseTypeType } from '../../utils/types';
import { checkAuctionEnded, checkAuctionStarted, mapItemsToArray } from '../../utils/helpers';
import { BidButtonType, BidMessageType } from '../../utils/types';
import {
  ApplicationSelectors,
  DashboardSelectors,
  EbidAuctionSelectors,
  LiveAuctionSelectors,
  UserSelectors,
} from '../selectors';
import { ItemSelectors } from './selectors';

export function createItemHooks(useSelector: typeof reduxUseSelector) {
  return {
    /**
     * Get the items included in the auction
     * @param saleEventId auction event id - `saleEventId`
     * @returns List of items from the auction
     */
    useItemsByAuctionId: (saleEventId: number | null) => {
      const itemsMap = useSelector(ItemSelectors.items);
      const filteredItems =
        saleEventId &&
        mapItemsToArray(itemsMap)
          .filter((item) => item.saleEvent?.id === saleEventId)
          .sort((a, b) => (a.saleEvent.lotNum > b.saleEvent.lotNum ? 1 : -1));
      return filteredItems || [];
    },

    /**
     * Get item detail info
     * @param itemId item id
     * @returns `Item` or null
     */
    useItem: (itemId: number): IItem.Item | null => {
      const itemsMap = useSelector(ItemSelectors.items);
      return itemsMap[itemId] || null;
    },

    useEbidItems: () => useSelector(ItemSelectors.ebidItems),

    useMyEbidItems: () => {
      const myEbidItems = useSelector(ItemSelectors.myEbidItems);

      enum SortOrder {
        asc = 1,
        desc = -1,
      }

      const sortByEndTime = (sortOrder: SortOrder = SortOrder.asc) => {
        return (a: IItem.Item, b: IItem.Item) =>
          (a?.saleEvent?.endAt! < b?.saleEvent?.endAt! ? -1 : 1) * sortOrder;
      };

      const runningEbidItems: IItem.Item[] = [];
      const endedEbidItems: IItem.Item[] = [];

      myEbidItems.forEach((item) => {
        if (item.bidding.isRunning) {
          runningEbidItems.push(item);
        } else {
          endedEbidItems.push(item);
        }
      });

      const sortedEbidItems = [
        ...runningEbidItems.sort(sortByEndTime(SortOrder.asc)),
        ...endedEbidItems.sort(sortByEndTime(SortOrder.desc)),
      ];

      return sortedEbidItems;
    },

    useRecommendations: () => useSelector(ItemSelectors.recommendations),

    useLatestVehicles: () => useSelector(ItemSelectors.latestVehicles),

    useVehicleListItems: (saleEventId: number | null) => {
      const itemsMap = useSelector(ItemSelectors.items);
      const liveItem = useSelector(LiveAuctionSelectors.currentItem);
      const liveItemId = +liveItem!?.ExternalID;

      const filteredItems =
        (saleEventId &&
          mapItemsToArray(itemsMap)
            .filter((item) => item.saleEvent?.id === saleEventId)
            .sort((a, b) => (a.saleEvent.lotNum > b.saleEvent.lotNum ? 1 : -1))) ||
        [];

      const startIndex = filteredItems.findIndex((item: IItem.Item) => item.id === liveItemId);
      const slicedItems = filteredItems.slice(startIndex);

      return slicedItems;
    },

    useRecentIndustrialItems: () => {
      const itemIds = useSelector(ItemSelectors.recentIndustrialItemIds);
      const itemsMap = useSelector(ItemSelectors.items);

      const _items: IItem.Item[] = [];

      itemIds.forEach((itemId) => {
        const existingItem = itemsMap[itemId];
        if (existingItem) {
          _items.push(existingItem);
        }
      });

      return _items;
    },

    useSearchResult: () => useSelector(ItemSelectors.searchResults),

    usePurchases: () => {
      const itemsMap = useSelector(ItemSelectors.items);
      const purchases = Object.values(itemsMap).filter((item) => !!item && !!item.purchase) || [];
      const sortByLatest = (a: IItem.Item, b: IItem.Item) =>
        a?.purchase?.soldAt! > b?.purchase?.soldAt! ? -1 : 1;
      const sortedPurchases = purchases.sort(sortByLatest);

      return sortedPurchases;
    },

    usePurchasesAndOffers: (purchaseType: MyPurchaseTypeType) => {
      const sortByLatest = (a: IItem.Item, b: IItem.Item) => {
        const aDate = a?.purchaseV2?.soldAt ?? a?.offer?.offerAt;
        const bDate = b?.purchaseV2?.soldAt ?? b?.offer?.offerAt;

        return aDate! > bDate! ? -1 : 1;
      };

      const sortByEarliest = (a: IItem.Item, b: IItem.Item) =>
        a?.purchaseV2?.soldAt! < b?.purchaseV2?.soldAt! ? -1 : 1;

      const purchasesAndOffersSelectors = {
        [MyPurchaseType.unpaid]: useSelector(ItemSelectors.unpaidPurchases).sort(sortByEarliest),
        [MyPurchaseType.paid]: useSelector(ItemSelectors.paidPurchases).sort(sortByLatest),
        [MyPurchaseType.offer]: useSelector(ItemSelectors.offers).sort(sortByLatest),
      };

      return purchasesAndOffersSelectors[purchaseType] || [];
    },

    useOffers: () => {
      const itemsMap = useSelector(ItemSelectors.items);
      const offers =
        Object.values(itemsMap).filter(
          (item: IItem.Item) => !!item && !!item.offer && !!item.bidding?.isOfferWinner,
        ) || [];
      const sortByLatest = (a: IItem.Item, b: IItem.Item) =>
        a?.offer?.offerAt! > b?.offer?.offerAt! ? -1 : 1;
      const sortedOffers = offers.sort(sortByLatest);

      return sortedOffers;
    },

    // Favorites

    useFavorites: () => {
      const items = useSelector(ItemSelectors.items);
      const favoriteIds = useSelector(ItemSelectors.favoritesIds);
      const favorites: IItem.Item[] = [];
      favoriteIds.forEach((id) => {
        const existingItem = items[id];
        if (existingItem && existingItem.favorite?.favorite) {
          favorites.push(existingItem);
        }
      });
      _logger.info('useFavorites', favorites);
      return favorites || [];
    },

    useFavoritesIds: () => useSelector(ItemSelectors.favoritesIds),

    useFavoritesTotal: () => {
      const favorites = useSelector(ItemSelectors.favoritesAll);

      if (favorites) {
        const favoritesCount = favorites.filter((item) => +item.favorite === 1).length;
        return favoritesCount;
      }

      return 0;
    },

    useItemFavorite: (itemId: number) => {
      const favorites = useSelector(ItemSelectors.favoritesAll);
      const favorite = favorites?.find((item) => item.itemId === itemId);
      return favorite && favorite.favorite;
    },

    // Prebids

    useItemPrebids: () => {
      const itemsMap = useSelector(ItemSelectors.items);
      const items: IItem.Item[] = Object.values(itemsMap).filter((item) => item && item.prebid);
      return items || [];
    },

    useItemPrebid: (itemId: number) => {
      const itemsMap = useSelector(ItemSelectors.items);
      return itemsMap[itemId];
    },

    // TODO: Once AI-4700 is tested, remove LiveRunning & EbidRunning in webapp, and live-item & ebid-item in mobile app codes
    // This hook eliminates the need to write duplicate codes in web app and mobile app respectively for live/ebid footer
    // Also to make sure the logic is consistent across platforms and can be easily managed in only one place
    useComputedValues: (
      itemId: number,
      auctionId?: number,
      isLiveAuctionScreen: boolean = false,
    ) => {
      let isAttend: boolean;
      let isPrebid: boolean;
      let isCurrentLiveItem: boolean;
      let isOffer: boolean;
      let displayBidAmount = 0;
      let isSocketConnected = false;
      let isBalanceSufficient = false;
      let insufficientBalanceAmount = 0;

      const items = useSelector(ItemSelectors.items);
      const item = items[itemId];

      // Do not remove this line, it is used to trigger re-render when timer changes
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const timer = useSelector(ApplicationSelectors.timer);

      const auctions = useSelector(DashboardSelectors.auctions);

      let auction: IAuction.Auction | undefined;
      if (auctionId) {
        auction = auctions[auctionId];
      }

      const liveItem = useSelector(LiveAuctionSelectors.currentItem);
      const auctionStatus = useSelector(LiveAuctionSelectors.auctionStatus);

      let isAuctionEnded = item && checkAuctionEnded(item);
      const isAuctionStarted = item && checkAuctionStarted(item);
      const isAuctionRunning = auctionStatus?.Status === ILive.AUCTION_STATUS.RUNNING;
      const isAuctionWaiting = (item?.isLive && isLiveAuctionScreen && !isAuctionRunning) ?? false;

      isCurrentLiveItem = !!(
        item?.isLive &&
        liveItem?.ExternalID &&
        item?.id === +liveItem?.ExternalID
      );

      isPrebid =
        (auction &&
          auction.isLive &&
          (!isAuctionRunning || !isCurrentLiveItem) &&
          (!isAuctionStarted || !isCurrentLiveItem) &&
          (!isAuctionEnded || isAuctionRunning)) ??
        false;

      isAttend =
        (auction &&
          auction.isLive &&
          auction?.simulcastLive === 1 &&
          !isLiveAuctionScreen &&
          !liveItem) ??
        false;

      isOffer =
        (!!item?.offer && !isNaN(item.offer?.offerAmount) && item.bidding?.isOffer) ?? false;

      const {
        currentBidAmount,
        isAsking,
        isNoSale,
        isRunning,
        isEnded,
        isSold,
        isLost,
        isOutbid,
        isWinning,
        isWon,
        nextBidAmount,
        maxBidInput,
        rejectedBid,
        sendingState,
      } = item?.bidding ?? {
        currentBidAmount: 0,
        isAsking: false,
        isNoSale: false,
        isRunning: false,
        sendingState: {
          isSending: false,
          sendingSince: 0,
          hasExceededDuration: false,
        },
        isSold: false,
        isLost: false,
        isWinning: false,
        isOutbid: false,
        isWon: false,
        nextBidAmount: 0,
        maxBidInput: 0,
      };

      const liveSocketStatus = useSelector(LiveAuctionSelectors.connectionStatus);
      const liveBalance = useSelector(LiveAuctionSelectors.bidderBalance);

      const ebidSocketStatus = useSelector(EbidAuctionSelectors.status);
      const ebidBalance = useSelector(UserSelectors.balance);

      if (item?.isLive) {
        isSocketConnected = liveSocketStatus?.connected ?? false;
        isBalanceSufficient = liveBalance >= (item?.depositAmt || 0);
      }

      if (item?.isEbid) {
        isSocketConnected = ebidSocketStatus?.connected ?? false;
        isBalanceSufficient = ebidBalance >= (item.depositAmt || 0);

        if (!isBalanceSufficient) {
          insufficientBalanceAmount = item.depositAmt - ebidBalance;
        }

        if (item?.bidding?.endTime) {
          const includeSniperTime = true;
          isAuctionEnded = item && checkAuctionEnded(item, includeSniperTime);
        }
      }

      const bidAmount = item?.bidAmount ?? item?.bidding?.currentBidAmount ?? 0;
      const isEbidRunning = item?.isEbid && isAuctionStarted && !isAuctionEnded;
      const isLiveRunning = item?.isLive && isRunning;

      if (isOffer && item?.offer?.offerAmount) {
        displayBidAmount = item?.offer?.offerAmount;
      } else if (isAsking || isNoSale || isSold || (item?.isLive && isWinning) || isWon || isLost) {
        displayBidAmount = currentBidAmount;
      } else if (item?.isLive) {
        displayBidAmount = nextBidAmount;
      } else if (item?.isEbid) {
        if (bidAmount === 0 && nextBidAmount) {
          displayBidAmount = nextBidAmount;
        } else if (isAuctionEnded) {
          displayBidAmount = currentBidAmount;
        } else if (
          (!isWinning && currentBidAmount >= bidAmount) ||
          (nextBidAmount > bidAmount && nextBidAmount > maxBidInput!)
        ) {
          displayBidAmount = nextBidAmount;
        } else if (maxBidInput && bidAmount > maxBidInput) {
          displayBidAmount = bidAmount;
        } else if (maxBidInput && maxBidInput >= nextBidAmount) {
          displayBidAmount = maxBidInput;
        } else {
          displayBidAmount = bidAmount;
        }
      }

      const showSetMaxBid =
        !isAuctionEnded &&
        ((isWinning && maxBidInput && displayBidAmount > maxBidInput) ||
          (!isWinning && displayBidAmount > nextBidAmount));

      const showUpdateMaxBid =
        isWinning &&
        maxBidInput &&
        displayBidAmount > maxBidInput &&
        maxBidInput > currentBidAmount;

      const showYourMaxBid =
        isWinning &&
        maxBidInput &&
        maxBidInput > currentBidAmount &&
        (item?.isEbid ? displayBidAmount && displayBidAmount <= maxBidInput : true);

      // We don't want this to happen on Staging/Prod build
      // console.table({
      //   isWinning,
      //   maxBidInput,
      //   currentBidAmount,
      //   displayBidAmount,
      //   nextBidAmount,
      //   showYourMaxBid,
      //   sendingState,
      // });

      const showNewItem =
        (!isRunning || isAuctionWaiting || !currentBidAmount) && !isOffer && !isWon && item?.isLive;

      const showSold = !isWinning && isSold;
      const showLost = isLost;
      const showSendingBid = sendingState?.isSending;
      const showTopUp = !isWinning && !isBalanceSufficient;
      const showOffer = isOffer;
      const showYouWin = isWon;
      const showOutbid = item?.isLive
        ? isOutbid
        : item?.isEbid
        ? !showSetMaxBid && isAuctionEnded && isOutbid
        : false;
      const showNoSale = isNoSale;
      const showWinning = item?.isLive
        ? isWinning && !showOffer
        : item?.isEbid
        ? !showSetMaxBid && isWinning
        : false;

      const showBidNow =
        !isWinning &&
        isBalanceSufficient &&
        !showOffer &&
        !showNoSale &&
        !showSold &&
        !showSetMaxBid &&
        !showUpdateMaxBid &&
        !showYourMaxBid &&
        (isLiveRunning || isEbidRunning);

      const showPendingResult =
        item?.isEbid &&
        item.bidding?.hasBids &&
        isAuctionEnded &&
        !isEnded &&
        !showYouWin &&
        !showOffer &&
        !showLost &&
        !showSold;

      const showCheckAuctions = item?.isEbid && !item.bidding?.hasBids && isAuctionEnded;

      const getBidAmountLabel = (): string => {
        const showEmpty = !isEbidRunning && !isLiveRunning;
        const showCurrentBid = (item?.isLive && isWinning) || isAuctionEnded;
        const showBidThisAmt = !isWinning && !(showSetMaxBid || showUpdateMaxBid);

        switch (true) {
          case isOffer:
            return 'labels:offer_this_amount';
          case isNoSale:
            return 'labels:highest_bid_amount';
          case isSold:
            return 'labels:sold_this_amount';
          case showCurrentBid:
            return 'labels:current_bid_lowercase';
          case showEmpty:
            return '';
          case showBidThisAmt:
            return 'labels:bid_this_amount';
          case showYourMaxBid:
            return 'labels:your_max_bid_lowercase';
          case showUpdateMaxBid:
            return 'labels:update_max_bid_lowercase';
          case showSetMaxBid:
            return 'labels:set_max_bid_lowercase';
          default:
            return 'unknown';
        }
      };

      const getBidButton = (): BidButtonType => {
        switch (true) {
          case showNewItem:
            return BidButtonType.NewItem;
          case showSendingBid:
            return BidButtonType.SendingBid;
          case showBidNow:
            return BidButtonType.BidNow;
          case showYouWin:
            return BidButtonType.YouWin;
          case showNoSale:
            return BidButtonType.NoSale;
          case showPendingResult:
            return BidButtonType.PendingResult;
          case showWinning:
            return BidButtonType.Winning;
          case showOffer:
            return BidButtonType.Offer;
          case showCheckAuctions:
            return BidButtonType.CheckAuctions;
          case showTopUp:
            return BidButtonType.TopUp;
          case showOutbid:
            return BidButtonType.Outbid;
          case showSold:
            return BidButtonType.Sold;
          case showLost:
            return BidButtonType.Lost;
          case showUpdateMaxBid:
            return BidButtonType.UpdateMaxBid;
          case showSetMaxBid:
            return BidButtonType.SetMaxBid;
          default:
            return BidButtonType.Unknown;
        }
      };

      const showBeFirstBidder =
        isAuctionStarted &&
        !isAuctionEnded &&
        isBalanceSufficient &&
        item.bidding?.bidsCount === 0 &&
        item?.isEbid;

      const showStartBidding =
        isAuctionStarted &&
        !isAuctionEnded &&
        isBalanceSufficient &&
        item.bidding?.bidsCount !== 0 &&
        !item.bidding?.hasBids &&
        item?.isEbid;

      const showAmountToTopUp =
        showTopUp && item?.isEbid && isAuctionStarted && !isAuctionEnded && !item.bidding?.hasBids;

      const showPrebidAccepted = item?.isLive && !!item?.prebid;

      const showSendingBidDelay = sendingState?.hasExceededDuration;

      // TODO: Below function created as part of AI-5242, to consider moving bid message logic for LiveAuctionScreen to below function as well later on
      const getBidMessage = (): BidMessageType => {
        switch (true) {
          case showSendingBidDelay: {
            return BidMessageType.SendingBidDelay;
          }
          case showSendingBid: {
            return BidMessageType.SendingBid;
          }
          case showPendingResult: {
            return BidMessageType.PendingResult;
          }
          case isWinning: {
            return BidMessageType.Winning;
          }
          case isOutbid: {
            return BidMessageType.Outbid;
          }
          case rejectedBid?.isFirstBidRejected: {
            return BidMessageType.LateBid;
          }
          case showBeFirstBidder: {
            return BidMessageType.BeFirstBidder;
          }
          case showStartBidding: {
            return BidMessageType.StartBidding;
          }
          case showAmountToTopUp: {
            return BidMessageType.TopUp;
          }
          case showYouWin: {
            return BidMessageType.YouWin;
          }
          case showLost: {
            return BidMessageType.Lost;
          }
          case showOffer: {
            return BidMessageType.Offer;
          }
          case showCheckAuctions: {
            return BidMessageType.CheckAuctions;
          }
          case showPrebidAccepted: {
            return BidMessageType.PrebidAccepted;
          }
          default:
            return BidMessageType.Unknown;
        }
      };

      // For purchases
      const isSaleFinalized = item?.status === IItem.STATUS.SOLD;
      const {
        soldAmt,
        paymentStatus,
        amountPayableToPickles,
        amountPayableToConsignor,
        totalPaidAmount,
        totalPendingApprovalAmount,
        payBy,
        finalizedAt,
      } = item?.purchaseV2 ?? {
        soldAmt: 0,
        paymentStatus: null,
        amountPayableToPickles: 0,
        amountPayableToConsignor: 0,
        totalPaidAmount: 0,
        totalPendingApprovalAmount: 0,
        payBy: '',
        finalizedAt: '',
      };

      const outstandingAmountToPickles =
        (amountPayableToPickles ?? 0) - (totalPaidAmount ?? 0) - (totalPendingApprovalAmount ?? 0);

      const totalSubmittedAmount = (totalPaidAmount ?? 0) + (totalPendingApprovalAmount ?? 0);

      return {
        isAuctionStarted,
        isAuctionEnded,
        isAuctionRunning,
        isAuctionWaiting,
        isAttend,
        isPrebid,
        isOffer,
        isCurrentLiveItem,
        isAsking,
        isNoSale,
        isRunning,
        sendingState,
        isSold,
        isWinning,
        isWon,
        isLost,
        isOutbid,
        currentBidAmount,
        nextBidAmount,
        displayBidAmount,
        bidAmountLabel: getBidAmountLabel(),
        bidButton: getBidButton(),
        isSocketConnected,
        maxBidInput,
        isBalanceSufficient,
        insufficientBalanceAmount,
        showUpdateMaxBid,
        showSetMaxBid,
        bidAmount,
        getBidMessage,
        // For purchases
        isSaleFinalized,
        soldAmt,
        paymentStatus,
        amountPayableToPickles,
        outstandingAmountToPickles,
        totalSubmittedAmount,
        amountPayableToConsignor,
        totalPaidAmount,
        totalPendingApprovalAmount,
        payBy,
        finalizedAt,
      };
    },
  };
}
