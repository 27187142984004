export const FILTER_OBJECT_AUCTION_TYPE = [
  {
    key: 'LIVE',
    label: 'Live',
    value: 'LIVE',
  },
  {
    key: 'STATIC',
    label: 'E-bidding',
    value: 'STATIC',
  },
];

export const FILTER_OBJECT_AUCTION_CATEGORY = [
  {
    key: 'SALVAGE',
    label: 'Salvage',
    value: 'SALVAGE',
  },
  {
    key: 'INDUSTRIAL',
    label: 'Industrial',
    value: 'INDUSTRIAL',
  },
  {
    key: 'USED_CARS',
    label: 'Used Cars',
    value: 'USED_CARS',
  },
];

export interface Pagination {
  page: number;
  total: number;
}

export interface AuctionPagination extends Pagination {
  id: number;
}

export interface SearchPagination extends Pagination {
  keyword: string;
  suggestions?: string[];
}

export interface ItemsListingFilter {
  year: string[];
  make: string[];
  model: string[];
  location?: string[];
  condition?: string[];
  category?: string[];
}

export enum PurchaseV2Filters {
  type = 'type',
  make = 'make',
  model = 'model',
  location = 'location',
}

export type PurchaseV2FiltersType = keyof typeof PurchaseV2Filters;

export enum EVENT_CATEGORY {
  SALVAGE = 'SALVAGE',
  INDUSTRIAL = 'INDUSTRIAL',
  USED_CARS = 'USED_CARS',
}

export type EventCategory = keyof typeof EVENT_CATEGORY;

export enum ConsignorPaymentReconciliation {
  AUCTION = 'AUCTION',
  CONSIGNOR = 'CONSIGNOR',
}

export type ConsignorPaymentReconciliationType = keyof typeof ConsignorPaymentReconciliation;

export enum PaymentTerms {
  '3WD' = '03WD',
  '5D' = '05D',
  '05D' = '05WD',
  '07D' = '07D',
  '07WD' = '07WD',
  '10D' = '10D',
  '14D' = '14D',
  '30D' = '30D',
}

export type PaymentTermsType = keyof typeof PaymentTerms;

export enum InvoiceType {
  BALANCE = 'BALANCE',
  BUYER = 'BUYER',
  DEPOSITS = 'DEPOSITS',
}

export type InvoiceTypeType = keyof typeof InvoiceType;

export enum PaymentMethod {
  CASH = 'CASH',
  CHECK = 'CHECK',
  EFT = 'EFT',
  CREDITCARD = 'CREDITCARD',
  MOLPAY = 'MOLPAY',
}

export type PaymentMethodType = keyof typeof PaymentMethod;
