import { AppProps } from 'next/app';
import { FC, useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import jsHttpCookie from 'cookie';
import jsCookie from 'js-cookie';
import Swal from 'sweetalert2';

import { appActions, ebidActions, userActions } from '@pickles/shared/redux/actions';
import { createLanguageObject } from '@pickles/shared/services/localization/languages';
import { generateApi } from '@pickles/shared/services/api';
import { IApp } from '@pickles/shared/models';
import { ResponseProblem, ResponseProblemType } from '@pickles/shared/services/api/response.types';
import { SUPPORT_PHONE } from '@pickles/shared/utils/config';
import Config from '@pickles/shared/config';
import { logoutErrors } from '@pickles/shared/utils/constants';

import info from '../package.json';
import { store } from '../app/redux';
import { UserHooks } from '../app/redux/hooks';
import ReduxProvider from '../app/redux/ReduxProvider';
import { Logger, LOG_LEVEL } from '../util/logger';
import { getBrandPoster } from './api/brand-poster';
import { getFooter } from './api/footer';
import { getPageNavigationData } from './api/navigation';
import Integrations from '../components/Integrations';

import '../styles/globals.css';
import '../components/ItemCard/my-style.css';
import './../components/Shared/Video/styles.css';

const DynatraceSdk = require('@dynatrace/oneagent-sdk');
export const i18n = createLanguageObject(initReactI18next);

// TODO jwt token
const getAuthToken = (token: string | null) => {
  if (token) {
    return `jwt ${token}`;
  }
  return null;
};

const onApiError = (error: ResponseProblemType | string, errorCode?: number) => {
  if (logoutErrors.includes(error)) {
    store.dispatch(userActions.logout());
  }

  const currentPath = window.location.pathname;
  const splitCurrentPath = currentPath.split('/');
  const isLiveAuctionPage = splitCurrentPath[1] === 'auctions' && splitCurrentPath[3] === 'live';

  if (isLiveAuctionPage && error === ResponseProblem.CLIENT_ERROR && errorCode === 404) {
    return;
  }

  Swal.fire({
    toast: true,
    position: 'top-end',
    title: 'Error',
    icon: 'error',
    text:
      error in ResponseProblem
        ? i18n.t(`errors:${error?.toLowerCase()}`, { phone: SUPPORT_PHONE, errorCode })
        : error,
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: false,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
};

export type AppSearchConfig = {
  ENABLED: boolean;
  URL: string;
  TOKEN: string;
};

export const initEnvironment = async (
  token: string | null,
  appEnv: IApp.Environment,
  processEnv: IApp.ProcessEnvironment,
  commitHash: string,
) => {
  const selectedEnv = jsCookie.get('env');
  const env =
    (selectedEnv && Config.isValidEnv(selectedEnv) && (selectedEnv as IApp.Environment)) ||
    (appEnv && Config.isValidEnv(appEnv) && appEnv) ||
    IApp.ENV.STAGING;
  jsCookie.set('env', env);

  Config.setProcessEnvironment(processEnv);
  Config.setApplicationEnvironment(env as IApp.Environment);
  // Config.setCommitHash(commitHash); // TODO: Remove once confirm Config.COMMIT_HASH is set in pipeline

  if (processEnv !== IApp.ENV.DEVELOPMENT) {
    // eslint-disable-next-line no-underscore-dangle
    globalThis._logger = new Logger(LOG_LEVEL.ERROR); // overwrite LOG_LEVEL for non Dev env
  }

  const { API_URL, APPSEARCH } = Config.getConfig(env);
  _logger.info('initEnv', { appEnv, env, API_URL, APPSEARCH });
  const headers = {
    'Cache-Control': 'no-cache',
    'App-Name': info.appName,
    'App-Version': info.version,
    Authorization: getAuthToken(token),
  };

  const options = {
    appsearch: APPSEARCH,
  };

  generateApi(API_URL, env, headers, onApiError, options);
};

type MyAppProps = AppProps & {
  footerData: unknown;
  brandPosterData: unknown;
  navigationData: unknown;
  appEnv: IApp.Environment;
  processEnv: IApp.ProcessEnvironment;
  commitHash: string;
};

function MyApp({
  Component,
  pageProps,
  footerData,
  brandPosterData,
  navigationData,
  appEnv,
  processEnv,
  commitHash,
}: MyAppProps) {
  const { user } = store.getState();
  const [isLoaded, setIsLoaded] = useState(false);

  const initApi = async () => {
    await initEnvironment(user.token, appEnv, processEnv, commitHash);
    _logger.info('config', Config);
    setIsLoaded(true);
  };

  const initDynatrace = () => {
    // Dynatrace initialisation
    let DynatraceApi: any;
    DynatraceApi = DynatraceSdk.createInstance({ id: 1 });

    if (DynatraceApi.getCurrentState() !== DynatraceSdk.SDKState.ACTIVE) {
      console.error('Initialize Dynatrace: SDK is not active!');
    }

    // install logging callbacks
    DynatraceApi.setLoggingCallbacks({
      warning: (msg: string) => console.error('Initialize Dynatrace SDK warning: ' + msg),
      error: (msg: string) => console.error('Initialize Dynatrace SDK error: ' + msg),
    });

    // this will return a TraceContextInfo where `isValid` is false and the traceId and spanId have their respective default values
    const currentTC = DynatraceApi.getTraceContextInfo();
    console.log(
      `valid: ${currentTC.isValid}, traceid: ${currentTC.traceid}, spanid: ${currentTC.spanid}`,
    );
  };

  useEffect(() => {
    initApi();

    if (Config.DT_APM_ENABLED) {
      initDynatrace();
    }
  }, []);

  if (!isLoaded && process.browser) {
    return null;
  }

  const isEnvProd = Config.getCurrentEnv() === 'production';

  return (
    <ReduxProvider>
      {isEnvProd && <Integrations />}
      <GoogleReCaptchaProvider reCaptchaKey={Config.RECAPTCHA_SITE_KEY}>
        <ConnectRTC
          {...pageProps}
          Component={Component}
          footerData={footerData}
          brandPosterData={brandPosterData}
          navigationData={navigationData}
          appEnv={appEnv}
          processEnv={processEnv}
          commitHash={commitHash}
        />
        <div id="portal-root" />
      </GoogleReCaptchaProvider>
    </ReduxProvider>
  );
}

if (typeof _logger !== 'object') {
  // initiate logger with level
  // eslint-disable-next-line no-underscore-dangle
  globalThis._logger = new Logger(LOG_LEVEL.DEBUG);
}

interface ConnectRTCInterface {
  Component: any;
  pageProps: any;
  footerData: any;
  brandPosterData: any;
  navigationData: any;
  appEnv: IApp.Environment;
  processEnv: IApp.ProcessEnvironment;
}

const ConnectRTC: FC<ConnectRTCInterface> = ({
  Component,
  footerData,
  brandPosterData,
  navigationData,
  appEnv,
  processEnv,
  ...pageProps
}) => {
  const account = UserHooks.useUserAccount();
  const accountId = account?.id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appActions.setProcessEnvironment(processEnv));
    dispatch(appActions.setApplicationEnvironment(appEnv));
    dispatch(appActions.setApplicationPlatform('web'));
  }, [appEnv, processEnv, dispatch]);

  useEffect(() => {
    dispatch(appActions.getMobileAppConfig());
    if (accountId) {
      _logger.info('Logged in as', { accountId });
      dispatch(ebidActions.watchEbidAuctionSocket());
      dispatch(ebidActions.getIncrementConfig());
      return () => {
        dispatch(ebidActions.watchSocketCancel());
      };
    }
  }, [dispatch, accountId]);

  return (
    <Component
      {...pageProps}
      footerData={footerData}
      brandPosterData={brandPosterData}
      navigationData={navigationData}
    />
  );
};

MyApp.getInitialProps = async ({ Component, ctx }: { Component: any; ctx: any }) => {
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  const processAppEnv =
    ((process.env.APP_ENV || Config.getCurrentProcessEnv()) as IApp.Environment) || undefined;

  const processEnv: IApp.ProcessEnvironment = processAppEnv || IApp.ENV.DEVELOPMENT;
  let appEnv: IApp.Environment = processAppEnv || IApp.ENV.STAGING;

  const cookies = ctx.req?.headers.cookie;
  if (cookies && typeof cookies === 'string') {
    const cookiesJSON = jsHttpCookie.parse(cookies);
    if (cookiesJSON.env && Config.isValidEnv(cookiesJSON.env)) {
      appEnv = cookiesJSON.env as IApp.Environment;
    }
  }

  Config.setConfig(appEnv);
  Config.setApplicationEnvironment(appEnv);
  Config.setProcessEnvironment(processEnv);

  const commitHash = process.env.GIT_COMMIT_HASH || 'Development';

  let footerData = {};
  let brandPosterData = {};
  let navigationData = {};
  try {
    footerData = await getFooter();
    brandPosterData = await getBrandPoster();
    navigationData = await getPageNavigationData();
  } catch (error) {
    footerData = {};
    brandPosterData = {};
    navigationData = {};
  }
  return { pageProps, footerData, brandPosterData, navigationData, appEnv, processEnv, commitHash };
};
export default MyApp;
