import apisauce, { ApiResponse, ApisauceInstance } from 'apisauce';
import humps from 'humps';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import qs from 'qs';
import { IApp, IWeb } from '../../models';
import { UserTransaction } from '../../models/user.types';
import {
  AuctionFilterBody,
  AuthTokenRequestBody,
  ChangePasswordRequestBody,
  EbidAddAutobidRequestBody,
  EbidItemRequestBody,
  EbidItemsRequestParams,
  GetMolPayConfigRequestBody,
  ItemsRequestBody,
  LiveLotDetailsRequestBody,
  LiveLotPreBidRequestBody,
  PageAndFieldsFilterBody,
  PaymentSubmissionRequestBody,
  PurchaseV2RequestParams,
  RegisterRequestBody,
  RegPushTokenRequestBody,
  SetFavoriteRequestBody,
  UpdateFavoriteRequestBody,
  UserPrefsBody,
} from './request.types';
import {
  AccountResponse,
  AddPrebidResponse,
  AppSearchResponse,
  AuctionDetailsResponse,
  AuctionFilterResponse,
  AuctionSocketTokenResponseData,
  AuthTokenResponse,
  DashboardResponse,
  EbidActivityResponse,
  EbidAuctionsConfigResponse,
  EbidHistoryResponse,
  EbidItemsResponse,
  EbidLotBidResponse,
  EbidLotDetailsResponse,
  GetFavoritesAllResponse,
  GetMolPayConfigResponse,
  GetPrebidsResponse,
  HoldLotDetailsResponse,
  ItemResponse,
  ItemsResponse,
  LiveLotDetailsResponse,
  MobileAppConfigResponse,
  OffersResponse,
  PaymentDetailsResponse,
  PaymentSubmissionResponse,
  PurchasesResponse,
  PurchasesV2Response,
  RecommendationsResponse,
  RegisterResponse,
  RemovePrebidResponse,
  ResetPasswordResponse,
  ResponseProblem,
  ResponseProblemType,
  SearchAutocompleteResponse,
  UploadFileResponse,
  UserPrefsResponse,
  WinningItemsResponse,
} from './response.types';

type TransactionHistoryRequestBody = {
  perpage: number;
};

type RecommendationsRequestParams = {
  page: number;
};

export let api: Api;

export class Api {
  private client: ApisauceInstance;
  private appSearchClient: ApisauceInstance;
  private showError?: boolean;
  private errorLastShown?: number;
  private responseError: ResponseProblemType;

  constructor(
    baseURL: string,
    env: IApp.Environment,
    headers: any,
    onApiError: (value: ResponseProblemType, errorCode?: number) => void,
    options: any,
  ) {
    _logger.info('Api', { baseURL, env });

    // Send marketplace app's env to API
    headers['App-Env'] = env;

    this.client = apisauce.create({
      baseURL,
      timeout: 100000,
      headers,
      withCredentials: false,
    });

    this.client.addResponseTransform((response) => {
      response.data = humps.camelizeKeys(response.data);
    });

    this.client.addRequestTransform((request) => {
      if (request.headers?.['Content-Type'] === 'application/json') {
        request.data = humps.decamelizeKeys(request.data);
        request.params = humps.decamelizeKeys(request.params);
      }
    });

    this.client.setBaseURL(baseURL);

    const monitor = (response: ApiResponse<any, any>) => {
      if (!response.ok) {
        _logger.error('ApiError', response);

        const timeNow = new Date().getTime() / 1000;
        const interval = 5;
        this.showError = !this.errorLastShown || timeNow >= this.errorLastShown + interval;
        // Check for errors thrown by the library
        switch (response.problem) {
          case ResponseProblem.CLIENT_ERROR:
          case ResponseProblem.CANCEL_ERROR: {
            if (response.status === 403 || response.status === 401) {
              this.responseError = 'AUTH_ERROR';
            } else {
              this.responseError = response.problem;
            }
            break;
          }
          default: {
            this.responseError = response.problem;
            break;
          }
        }

        // Check for token expry error
        const authHeader = this.client.headers?.['Authorization'];
        if (authHeader) {
          const token = authHeader.split(' ')[1];
          const decoded: any = jwt_decode(token);
          const currentTime = moment().unix();
          const isTokenExpired = decoded.exp && currentTime >= decoded.exp;
          if (isTokenExpired) {
            this.responseError = 'TOKEN_EXPIRED_ERROR';
          }
        }

        if (this.showError) {
          this.errorLastShown = timeNow;
          onApiError(this.responseError, response.status);
        }
      }
    };

    this.client.addMonitor(monitor);

    if (options.appsearch) {
      this.setupAppSearchClient(options.appsearch.URL, options.appsearch.TOKEN, monitor);
    }
  }

  setupAppSearchClient = (appSearchBaseUrl: string, appSearchToken: string, monitor: any) => {
    this.appSearchClient = apisauce.create({
      baseURL: appSearchBaseUrl,
      timeout: 10000,
      withCredentials: true,
    });

    this.appSearchClient.setHeader('Authorization', `Bearer ${appSearchToken}`);

    this.appSearchClient.addResponseTransform((response) => {
      response.data = humps.camelizeKeys(response.data);
    });

    this.appSearchClient.addMonitor(monitor);
  };

  setAuthHeader = (token: string) => {
    this.client.setHeader('Authorization', `jwt ${token}`);
  };

  removeAuthHeader = () => {
    delete this.client.headers.Authorization;
  };

  getMobileAppConfig = () => {
    return this.client.get<MobileAppConfigResponse>('/system/mobileversion');
  };

  getAuthToken = (data: AuthTokenRequestBody) => {
    return this.client.post<AuthTokenResponse>('/auth/login-jwt', data);
  };

  registerUser = (body: RegisterRequestBody) => {
    return this.client.post<RegisterResponse>('/marketplace/users', body);
  };

  uploadPrefs = (data: UserPrefsBody) => {
    return this.client.put<UserPrefsResponse>('/marketplace/prefs', data);
  };

  getAccount = () => {
    return this.client.get<AccountResponse>('/marketplace/account');
  };

  getDashboard = () => {
    return this.client.get<DashboardResponse>('/marketplace/dashboard');
  };

  getOffers = () => {
    return this.client.get<OffersResponse>('/marketplace/offers?show_images=true');
  };

  /**
   * Get the list of items by applying the filter
   * @param params filters
   * @returns list of items
   */
  getItems = (params: ItemsRequestBody) => {
    return this.client.get<ItemsResponse>('/marketplace/v2/items', params);
  };

  getItem = (itemId: string | number) => {
    return this.client.get<ItemResponse>(`/marketplace/v2/items/${itemId}`);
  };

  // E-bidding Endpoints - Group Start
  getEbidItems = (params: EbidItemsRequestParams) => {
    return this.client.get<EbidItemsResponse>(
      '/marketplace/e-bidding/v2/items?show_images=true',
      params,
    );
  };
  postEbidAutobid = (params: EbidAddAutobidRequestBody) => {
    return this.client.post<EbidLotBidResponse>('/marketplace/e-bidding/auto-bid', params);
  };
  getEbidConfig = () => {
    return this.client.get<EbidAuctionsConfigResponse>('/marketplace/e-bidding/bid-configs');
  };
  getEbidItemById = (params: EbidItemRequestBody) => {
    _logger.info('getEbidItemById', { params });
    const url = `/marketplace/e-bidding/v2/items/${params.itemId}`;
    return this.client.get<EbidLotDetailsResponse>(url, params);
  };
  getEbidHistoryByItem = (id: number) => {
    return this.client.get<EbidHistoryResponse, string>(
      `/marketplace/e-bidding/items/${id}/bid-history`,
    );
  };
  // blocked deposit
  getEbidWinningItemsByBidder = (userId: number) => {
    return this.client.get<WinningItemsResponse, string>(
      `/marketplace/e-bidding/bidders/${userId}/winning-items`,
    );
  };
  // bid activity
  getEbidActivity = () => {
    const url = '/marketplace/e-bidding/activity';
    return this.client.get<EbidActivityResponse>(url);
  };
  // E-bidding Endpoints - Group End

  getAuctionFilter = (data?: AuctionFilterBody) => {
    return this.client.get<AuctionFilterResponse>('marketplace/events', data);
  };

  getAuctionDetails = (eventId: number) => {
    const url = `/marketplace/events/${eventId}`;
    return this.client.get<AuctionDetailsResponse>(url);
  };

  getLiveLotDetails = (params: LiveLotDetailsRequestBody) => {
    const url = `/marketplace/v2/items/${params.itemId}`;
    return this.client.get<LiveLotDetailsResponse>(url, params);
  };

  addLiveLotPreBid = (params: LiveLotPreBidRequestBody) => {
    return this.client.post<AddPrebidResponse>('/marketplace/prebids', params);
  };

  deleteLiveLotPreBid = (id: number) => {
    return this.client.delete<RemovePrebidResponse>(`/marketplace/prebids/${id}`);
  };

  getAuctionBadge = async (id: number) => {
    return this.client.get(`/marketplace/events/${id}/badge`);
  };

  getAuctionAttendeeLink = async (id: number, _user: any) => {
    return this.client.get(`/marketplace/events/${id}/attendee-link?attach_token=true`);
  };

  getAuctionSocketToken = (url: string) => {
    return this.client.get<AuctionSocketTokenResponseData>(url);
  };

  getTransactionHistory = () => {
    return this.client.get<UserTransaction[], string>('/marketplace/v2/transactions');
  };

  getMolPayConfig = (data: GetMolPayConfigRequestBody) => {
    return this.client.post<GetMolPayConfigResponse, string>(
      '/integrations/molpay/v2/config',
      data,
      {
        transformResponse: (response) => response,
      },
    );
  };

  getHoldLotDetails = (id: number) => {
    return this.client.get<HoldLotDetailsResponse>(`/marketplace/v2/items/${id}`);
  };

  uploadFile = (data: FormData, id: number) => {
    const url = `/marketplace/users/${id}/image`;

    return this.client.put<UploadFileResponse, string>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  resetPassword = (email: string) => {
    return this.client.post<ResetPasswordResponse, string>(`users/${email}/password-reset`);
  };

  changePassword = (data: ChangePasswordRequestBody) => {
    return this.client.get<string, string>('', data);
  };

  getPurchases = (params?: PageAndFieldsFilterBody) => {
    return this.client.get<PurchasesResponse, string>('/marketplace/purchases', params);
  };

  getPurchasesV2 = (params?: PurchaseV2RequestParams) => {
    return this.client.get<PurchasesV2Response, string>('/marketplace/v2/purchases', params);
  };

  getPaymentDetails = (itemId: string | number) => {
    return this.client.get<PaymentDetailsResponse>(`/marketplace/v2/items/${itemId}/payments`);
  };

  submitPayment = (data: PaymentSubmissionRequestBody) => {
    return this.client.post<PaymentSubmissionResponse>('/marketplace/payments', data);
  };

  uploadPaymentProof = (data: FormData, buyerPaymentId: number) => {
    const url = `/marketplace/payments/${buyerPaymentId}/document`;

    return this.client.put<UploadFileResponse, string>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  getPrebids = (params?: PageAndFieldsFilterBody) => {
    return this.client.get<GetPrebidsResponse, string>('/marketplace/prebids', params);
  };

  getFavoritesAll = () => {
    return this.client.get<GetFavoritesAllResponse, string>('/marketplace/favorites/all');
  };

  getFavorites = (params?: PageAndFieldsFilterBody) => {
    return this.client.get('/marketplace/favorites', params);
  };

  updateFavorites = (id: number, data: UpdateFavoriteRequestBody) => {
    return this.client.patch(`/marketplace/favorites/${id}`, data);
  };

  setFavorites = (data: SetFavoriteRequestBody) => {
    return this.client.post('/marketplace/favorites', data);
  };

  regPushToken = (data: RegPushTokenRequestBody) => {
    this.client.post<undefined, string>('/marketplace/notifications/device::bind', data);
  };

  getRecommendations = (params?: RecommendationsRequestParams) => {
    const query = qs.stringify({ page: params?.page });

    return this.client.get<RecommendationsResponse, string>(
      `/marketplace/recommendations?${query}`,
    );
  };

  // getSearchResult = (search: string) => {
  //   return this.client.get<SearchResultResponse>(
  //     `marketplace/v2/items?search=${search}&aggs=${true}`,
  //   );
  // };

  /**
   * Get the suggestion list according to the keyword provided
   * @param searchAuto keyword to search
   * @returns suggestion list
   */
  getAppSearchQuerySuggestion = (searchAuto: string) => {
    const data = { query: searchAuto };
    return this.appSearchClient.post<SearchAutocompleteResponse, string>('/query_suggestion', data);
  };

  getAppSearchResult = (params: any) => {
    return this.appSearchClient.post<AppSearchResponse>('/search.json', params);
  };

  submitMailer = (params: IWeb.ContactUsForm | IWeb.SubscribeForm) => {
    return this.client.post('/marketplace/mailer', params);
  };
}

export function generateApi(
  baseURL: string,
  env: IApp.Environment,
  headers: any,
  onApiError: any,
  options?: any,
) {
  api = new Api(baseURL, env, headers, onApiError, options);
}
