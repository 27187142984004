export default {
  primary: '#004C98',
  lightBlue: '#00A1CB',
  toggleBlue: '#08B1E7',
  green: '#5BAD2A',
  orange: '#F17505',
  red: '#EE1B20',
  white: '#FFFFFF',
  white_01: 'rgba(225, 255, 255, 0.1)',
  white_07: 'rgba(225, 255, 255, 0.7)',
  white_04: 'rgba(225, 255, 255, 0.4)',
  white_02: 'rgba(225, 255, 255, 0.2)',
  white_005: 'rgba(225, 255, 255, 0.05)',
  white_001: 'rgba(225, 255, 255, 0.01)',
  white_095: 'rgba(255, 255, 255, 0.95)',
  dash_white: '#D1DCF9',
  dash_white_01: 'rgba(209, 220, 249, 0.1)',
  black: '#434343',
  black_008: 'rgba(0, 0, 0, 0.08)',
  black_009: 'rgba(0, 0, 0, 0.09)',
  border: 'rgba(51, 77, 112, 0.7)',
  gray: '#7F7F7F',
  lightGray: '#8D8D8D',
  darkBlue: '#004C93',
  defaultBackground: 'rgb(242, 242, 242)',
  background: '#E5E5E5',
  //
  rhino_black: '#263A54',
  charcoal_grey_06: 'rgba(49, 54, 62, 0.6)',
  san_juan_grey: '#334D70',
  bali_hai_grey: '#8393A8',
  blumine_main_blue: '#1C4D8E',
  blumine_main_blue_05: 'rgba(28, 77, 142, 0.5)',
  blumine_main_blue_013: 'rgba(28, 77, 142, 0.13)',
  blue_zodiak: '#122e52',
  mischka: '#D6DADF',
  mischka_024: 'rgba(214, 218, 223, 0.24)',
  mischka_05: 'rgba(214, 218, 223, 0.5)',
  light_grey: 'rgba(51, 77, 112, 0.6)',
  zircon_light_blue: '#F2F6FF',
  zircon_light_blue_012: 'rgba(242, 246, 255, 0.12)',
  cerulean_blue: '#08AADE',
  christi_green: '#27A607',
  christi_green_01: 'rgba(39, 166, 7, 0.1)',
  cerise_red: '#DF2955',
  cerise_red_01: 'rgba(223, 41, 85, 0.1)',
  selago_light_blue: 'rgba(225, 232, 250, 1)',
  selago_light_blue_02: 'rgba(225, 232, 250, 0.2)',
  new_gray: '#596E8A',
  yellow: '#FED875',
  jaffa: '#ed7934',
  jaffa_01: 'rgba(237, 121, 52, 0.1)',
  polo_blue: '#8da6c7',
  selago: '#e1e8fa',
  jordy_blue: '#79abee',
  azure: '#3765a2',
  bali_hai: '#8594a9',
  astronaut: '#214471',
  catskill_white: '#e1e8f0',
  periwinkle_gray: '#c7d4e8',
  outbid: '#DF2955',
  winning: '#27A607',
  waikawa_gres: '#596e8a',
  tangerine_gradient_start: '#ED7734',
  tangerine_gradient_end: '#DF6F27',
  green_gradient_start: '#31bb0e',
  green_gradient_end: '#31af12',
  red_gradient_start: '#FB406D',
  red_gradient_end: '#E82655',
  icon_gray: '#BBCADD',
};
